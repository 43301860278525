import { twMerge } from "tailwind-merge";

import Icon, { type IconName } from "./icon";

export interface FeaturedIconProps {
  name: IconName;
  size?: "responsive" | "small" | "medium" | "large";
  className?: string;
}

const FeaturedIcon: React.FC<FeaturedIconProps> = ({
  name,
  size = "responsive",
  className = undefined,
}) => {
  let figureClassName = "rounded-full bg-primary-200 cosma:bg-[#eaeaea]";
  let iconClassName = "text-tertiary-500 cosma:text-text";

  switch (size) {
    case "small":
      figureClassName = twMerge(figureClassName, "p-2", className);
      iconClassName = twMerge(iconClassName, "h-4 w-4");
      break;
    case "medium":
      figureClassName = twMerge(figureClassName, "p-3", className);
      iconClassName = twMerge(iconClassName, "size-6");
      break;
    case "large":
      figureClassName = twMerge(figureClassName, "p-3", className);
      iconClassName = twMerge(iconClassName, "size-8");
      break;
    default: // "responsive"
      figureClassName = twMerge(figureClassName, "p-2 laptop:p-3", className);
      iconClassName = twMerge(iconClassName, "h-4 w-4 tablet:h-6 tablet:w-6");
      break;
  }

  return (
    <figure className={figureClassName}>
      <Icon name={name} className={iconClassName} />
    </figure>
  );
};

export { FeaturedIcon };
